import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import store from "../store";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "",
    component: () => import("../views/layout/LoggedInLayout.vue"),
    children: [
      // {
      //   title: 'Dashboard',
      //   path: '',
      //   name: 'dashboard',
      //   component: () => import('../views/dashboard/Dashboard.vue'),
      // },
      {
        title: "Dashboard",
        path: "",
        name: "Dashboard",
        component: () => import("../views/dashboard/Dashboard.vue"),
      },
      {
        title: "Blacklist",
        path: "blacklist",
        name: "Blacklist",
        component: () => import("../views/blacklist/Blacklist.vue"),
      },
      {
        title: "Events",
        path: "events",
        name: "Events",
        component: () => import("../views/events/Events.vue"),
      },
      {
        title: "Search",
        path: "search",
        name: "Search",
        component: () => import("../views/search/Search.vue"),
      },
      {
        title: "Map",
        path: "/map",
        name: "map",
        component: () => import("../views/map/Map.vue"),
      },
      {
        title: "Recipients",
        path: "/recipients",
        name: "recipients",
        component: () => import("../views/recipients/Recipients.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !store.state.auth.status.loggedIn) {
    console.info("Not logged in and not loading login page, redirecting!");
    return next({ name: "Login" });
  }

  next();
});

export default router;
