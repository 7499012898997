import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import axios from 'axios';
import Swal from 'sweetalert2';

const app = createApp(App).use(i18n);

app.mixin({
  methods: {
    $success(msg){
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'success',
            title: `${msg}`
        });
    },
    $error(msg, error = null){
        if(error !== null){
            if(axios.isCancel(error)) return;
            if(error.message === 'error.response is undefined') return;
            console.log(error);
        }
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'error',
            title: `${msg}`
        });
    },
    $warning(msg){
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'warning',
            title: `${msg}`
        });
    },
    $info(msg){
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        Toast.fire({
            icon: 'info',
            title: `${msg}`
        });
    },
    $can(permissionName) {
        if(!this.$store.state.auth.user.permissions) return false;
        return this.$store.state.auth.user.permissions.includes(permissionName);
    }
}
})

import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';

app.use(router).use(store).use(VueViewer)
.mixin({
    methods: {

    }
}).mount('#app');


let cancelSource = axios.CancelToken.source();

const requestInterceptor = config => {
    config.cancelToken = cancelSource.token;
    return config;
};

axios.interceptors.request.use(requestInterceptor);

axios.interceptors.response.use((response) => {
  return response;
}, error => {
  if(!error.response){
      return Promise.reject(error);
      // console.log("No error response");
      // return error;
  }
  if(error.response.status === 401){
      if((error.response.data.message === "Unauthenticated." || error.response.data.message === "Unauthorized" || error.response.data === "Unauthorized") && window.location.pathname !== "/login"){
        store.dispatch('auth/logout').then(() => {
          router.replace('/login');
        });
      }
  }

  if(error.response.status === 403){
      if(error.response.data !== undefined){
          if(error.response.data['2fa'] === 'required'){
              console.info("2FA IS REQUIRED");
              router.replace({name: '2fa', params: {
                      'route': router.currentRoute.name,
                      'params': router.currentRoute.params,
                      'first': true
                  }});
              return error;
          }
          if(error.response.data['2fa'] === 'expired'){
              console.info("2FA IS REQUIRED");
              router.replace({name: 'twoFactorAuth', params: {
                      'route': router.currentRoute.name,
                      'first': false
                  }});
              return error;
          }
      }
      console.log("Error Response is 403");
      router.replace('/');
  }

  if(axios.isCancel(error)){
      console.log("Error is due to cancellation by user.");
  }
  return Promise.reject(error);
  // return error;
});

