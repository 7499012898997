<template>
  <router-view/>
</template>

<script>
  export default {
    created: function() {
      window.addEventListener('keyup', this.historyBack)
      if(this.$store.state.auth.user){
        this.$store.dispatch('auth/checkToken', this.$store.state.auth.user.token)
        .catch(() => {
          this.$store.dispatch('auth/logout');
          this.$router.push('/login');
        });
      }
    },
    methods: {
      // historyBack(event) {
      //   if(event.keyCode === 32) {
      //     this.$router.go(-1);
      //   }
      // }
    }
  }
</script>
